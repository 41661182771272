import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { InferType } from 'yup';

import Button from 'ui-kit/button/button';
import FormSelect from 'ui-kit/form-select/form-select';
import Text from 'ui-kit/text/text';

import { getDaysOfMonthOptions, getYearOptions, monthOptionsNumberValue } from 'const/options';

import { EASY_AUTO_REFILL_SCHEMA } from '../../schema/easy-refill';

interface EasyRefillFormProps {
    onSubmit: (e: InferType<typeof EASY_AUTO_REFILL_SCHEMA>) => void;
    formName: string;
    submittingForm: boolean;
}

export const EasyRefillForm: React.FC<EasyRefillFormProps> = ({ onSubmit, formName, submittingForm }) => {
    const { t } = useTranslation();
    const minYear = new Date().getFullYear() - 120;
    const yearOptions = useMemo(() => getYearOptions({ min: minYear }), [minYear]);
    const dayOptions = useMemo(() => getDaysOfMonthOptions(), []);

    return (
        <Formik
            onSubmit={onSubmit}
            validationSchema={EASY_AUTO_REFILL_SCHEMA}
            // @ts-expect-error not sure what is causing this error. Needs investigation.
            initialValues={{
                dobMonth: '',
                dobDay: '',
                dobYear: '',
                zipcode: ''
            }}
        >
            {({
                initialValues,
                isValid,
                values,
                touched,
                errors,
                status,
                handleChange,
                handleSubmit,
                isSubmitting,
                dirty
            }) => (
                <Form
                    id="easy-refill-form"
                    className=""
                    data-ga-form-name={formName}
                    onSubmit={handleSubmit}
                    autoComplete="off"
                >
                    <p className="auto-refill-dob-label">{t('forms.easyAutoRefill.dob')}</p>
                    <Row className="easy-refill-dob-wrapper">
                        <Col className="auto-refill-dob-month" xs={6} lg={4}>
                            <Field
                                id="easy-refill-dob-month"
                                name="dobMonth"
                                options={monthOptionsNumberValue}
                                component={FormSelect}
                                placeholder={t('forms.easyAutoRefill.dobMonth')}
                                errors={errors?.dobMonth ? t(`forms.easyAutoRefill.errors.dobMonth`) : undefined}
                                touched={touched.dobMonth}
                                value={values.dobMonth}
                                initialValues={initialValues.dobMonth}
                            />
                        </Col>
                        <Col className="auto-refill-dob-day" xs={6} lg={4}>
                            <Field
                                id="easy-refill-dob-day"
                                name="dobDay"
                                options={dayOptions}
                                component={FormSelect}
                                placeholder={t('forms.easyAutoRefill.dobDay')}
                                errors={errors?.dobDay ? t(`forms.easyAutoRefill.errors.dobDay`) : undefined}
                                touched={touched.dobDay}
                                values={values.dobDay}
                                initialValues={initialValues.dobDay}
                            />
                        </Col>
                        <Col className="auto-refill-dob-year" xs={12} lg={4}>
                            <Field
                                id="easy-refill-dob-year"
                                name="dobYear"
                                options={yearOptions}
                                component={FormSelect}
                                placeholder={t('forms.easyAutoRefill.dobYear')}
                                errors={
                                    errors?.dobYear
                                        ? errors?.dobYear === 'Future'
                                            ? t(`forms.easyAutoRefill.errors.dobYearFuture`)
                                            : t(`forms.easyAutoRefill.errors.dobYear`)
                                        : undefined
                                }
                                touched={touched.dobYear}
                                values={values.dobYear}
                                initialValues={initialValues.dobYear}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <Text
                                name="zipcode"
                                label={t('forms.easyAutoRefill.zipCode')}
                                onChange={handleChange}
                                errors={errors?.zipcode ? t(`forms.easyAutoRefill.errors.zipCode`) : undefined}
                                touched={touched.zipcode}
                                value={values?.zipcode}
                                defaultValue={initialValues.zipcode}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="easy-refill-button-container">
                            <Button
                                async
                                label={t('forms.easyAutoRefill.submitBtn')}
                                variant="primary"
                                type="submit"
                                dataGAFormName={formName}
                                dataGAFormStep="1-EasyRefill"
                                dataGAFormStepName="EasyRefill-UserVerification"
                                disabled={!isValid || (!values.dobMonth?.length && !dirty) || submittingForm}
                                isBusy={submittingForm}
                            />
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>
    );
};
