import { RX_AVAILABLE_FLOWS } from 'enums/prescription';
import { graphql, navigate } from 'gatsby';
import { ALLOW_INSURED_BIRDI_PRICE } from 'gatsby-env-variables';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { sortBy } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import sanitizeHtml from 'sanitize-html';

// UI Kit & Components
import CircleInfo from 'ui-kit/icons/info/circle-info-icon';
import NoRefillsIcon from 'ui-kit/illustrations/no-refills/no-refills';
import PageSection from 'ui-kit/page-section/page-section';
import Spinner from 'ui-kit/spinner/spinner';

import EmptyState from 'display-components/empty-state/empty-state';

import { AutoRefillLearnMoreModal } from 'components/auto-refill-learn-more-modal';
import AutoRefillOtherInfo from 'components/auto-refill-other-info/auto-refill-other-info.component';
import { AutoRefillTermsAndConditionsModal } from 'components/auto-refill-terms-and-conditions-modal';
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import InternalHeader from 'components/internal-header/internal-header.component';
import MarketingPageLayout from 'components/layouts/page/marketingpage.layout';
import PrescriptionTabs from 'components/prescriptions-list/prescription-tabs/prescription-tabs.component';
import PrescriptionCard from 'components/prescriptions-list/prescriptions-card/prescriptions-card.component';

// Auto Refill
import {
    autoRefillGetPatientAdressesRoutine,
    autoRefillGetPatientDataRoutine,
    autoRefillGetPatientPaymentCardsRoutine
} from 'state/auto-refill/auto-refill.reducer';
import {
    autoRefillAcCodeSelector,
    autoRefillAccountHasInsuranceSelector,
    autoRefillAccountIsCaliforniaUserSelector,
    autoRefillAdressesSelector,
    autoRefillDefaultAddressSelector,
    autoRefillEligibleFamilyDataSelector,
    autoRefillePostPatientNumberSelector,
    autoRefillFamilyDataSelector,
    autoRefillFirstNameSelector,
    autoRefillIsCaregiverSelector,
    autoRefillLoadingRxsSelector,
    autoRefillPatientPlanSelector,
    autoRefillRxsLoadedSelector,
    autoRefillToggleRxBusySelector,
    autoRefillUserBearerTokenSelector
} from 'state/auto-refill/auto-refill.selectors';
// Modals
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { drugDiscountPriceRoutine } from 'state/drug/drug.routines';

import { lowercaseAndCapitalize } from 'util/cart';
import { prescriptionPayloadToProps } from 'util/payload-to-props';

import { useAutoRefillToggle } from 'hooks/useAutoRefillToggle';

// Hoc & Types
import withUnauthenticatedSessionExpiration from 'hoc/withUnauthenticatedSessionExpiration';

import { EasyRefillErrorModal } from '../../easy-refill';
import './index.style.scss';

const AutoRefillPrescriptions = ({
    data
}: {
    data: GatsbyTypes.GetAutoRefillPrescriptionLanguageAndImageDataQuery;
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { blueSkyBackground } = data;

    // --------------------
    // Selectors
    // -------------------
    const autoRefillAddresses = useSelector(autoRefillAdressesSelector);
    const firstName: string = useSelector(autoRefillFirstNameSelector);
    const autoRefillLoadingRxs = useSelector(autoRefillLoadingRxsSelector);
    const autoRefillBearerToken = useSelector(autoRefillUserBearerTokenSelector);
    const autoRefillRxsLoaded = useSelector(autoRefillRxsLoadedSelector);
    const autoRefillBusy = useSelector(autoRefillToggleRxBusySelector);
    const autoRefillAccountHasInsurance = useSelector(autoRefillAccountHasInsuranceSelector);
    const autoRefillePostPatientNumber = useSelector(autoRefillePostPatientNumberSelector);
    const autoRefillIsCaregiver = useSelector(autoRefillIsCaregiverSelector);
    const autoRefillFamilyData = useSelector(autoRefillFamilyDataSelector);
    const autoRefillFamilyDataFiltered = useSelector(autoRefillEligibleFamilyDataSelector);
    const isCaliforniaUser = useSelector(autoRefillAccountIsCaliforniaUserSelector);
    const defaultAddress = useSelector(autoRefillDefaultAddressSelector);
    const isFamilyHasRxs = useSelector(autoRefillEligibleFamilyDataSelector);
    const planAlias = useSelector(autoRefillAcCodeSelector);
    const autoRefillPlanEligible = useSelector(autoRefillPatientPlanSelector);
    const { handleToggleAutoRefill } = useAutoRefillToggle();

    // --------------------
    // Local state
    // -------------------
    const [activeDependentTab, setActiveDependentTab] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    // --------------------
    // Memoized values
    // -------------------

    // Create a variable that returns dependents information
    const dependents = useMemo(() => {
        if (autoRefillFamilyData.length > 0) {
            return autoRefillFamilyData.map((item, key) => ({
                ePostPatientNum: item.epostPatientNum,
                familyMemberName: lowercaseAndCapitalize(`${item.firstName || ''}`),
                id: key,
                planAlias: item.planAlias
            }));
        } else {
            return [];
        }
    }, [autoRefillFamilyData]);

    const selectedPatientData = useMemo(
        () => autoRefillFamilyDataFiltered.find((patient) => patient.epostPatientNum === activeDependentTab) || null,
        [activeDependentTab, autoRefillFamilyDataFiltered]
    );

    const filteredRxs = useMemo(() => {
        if (!selectedPatientData || selectedPatientData.patientAutoRefill === false) return [];

        // TODO: There is no a way to know if the plan is onDemand at the dependent
        // level being returned in the Easy refill APIs. Hardcoding for now.
        const isOnDemandPlan = false;

        return selectedPatientData.rxResults.length > 0
            ? selectedPatientData.rxResults.map((prescription) =>
                  prescriptionPayloadToProps(prescription, t, selectedPatientData.planAlias, isOnDemandPlan)
              )
            : [];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPatientData]);

    const orderedRxs = useMemo(() => sortBy(filteredRxs, ['sortOrder', 'prescriptionName']), [filteredRxs]);

    const isPatientAutoRefillEligible = useMemo(() => {
        return autoRefillIsCaregiver ? selectedPatientData?.patientAutoRefill : autoRefillPlanEligible;
    }, [autoRefillIsCaregiver, autoRefillPlanEligible, selectedPatientData]);

    // --------------------
    // Callbacks
    // -------------------

    // Display error modal
    const handleShowErrorModal = useCallback(
        (errorText: string) => {
            dispatch(
                openModal({
                    showClose: true,
                    size: 'lg',
                    type: 'danger',
                    headerContent: (
                        <BirdiModalHeaderDanger headerText={t('modals.easyRefillFailure.title')} icon="alert" />
                    ),
                    bodyContent: <EasyRefillErrorModal translation={t} errorMessage={errorText} />,
                    ctas: [
                        {
                            label: t('modals.autoRefillFailure.buttonText'),
                            variant: 'primary',
                            onClick: () => {
                                dispatch(closeModal({}));
                            },
                            dataGALocation: 'EasyRefillNotVerified'
                        }
                    ]
                })
            );
        },
        [dispatch, t]
    );

    // Function that displays the learn more about auto refill modal
    const handleAutoRefillLearnMoreModal = useCallback(() => {
        dispatch(
            openModal({
                showClose: true,
                ctas: [
                    {
                        label: t('modals.autoRefillLearnMore.gotIt'),
                        variant: 'primary',
                        onClick: () => {
                            dispatch(closeModal({}));
                        }
                    }
                ],
                bodyContent: <AutoRefillLearnMoreModal t={t} />
            })
        );
    }, [dispatch, t]);

    // Function that handles a tab change
    // TODO: When refactoring consider another approach to don't need to
    // pass an unused tab property.
    const handleTabItemClick = (tab = 'all', dependent: string | null) => {
        setActiveDependentTab(dependent);
    };

    // Handle Auto refill toggle click
    const onChangeAutoRefill = useCallback(
        (rxNumber: string, rxSeqNumber: string, autoRefillEnabled: boolean, isRenew?: boolean) => {
            const processedRxs = autoRefillIsCaregiver ? filteredRxs : orderedRxs;
            const hasAnyRxAutorefillOn = processedRxs?.some((rx) => rx.autoRefillEnabled);
            handleToggleAutoRefill(
                { rxNumber: rxNumber, rxSeqNum: rxSeqNumber },
                autoRefillEnabled,
                hasAnyRxAutorefillOn,
                <AutoRefillTermsAndConditionsModal t={t} />,
                isRenew
            );
        },
        [handleToggleAutoRefill, t, filteredRxs, autoRefillIsCaregiver, orderedRxs]
    );

    // --------------------
    // Use effect
    // -------------------

    // If the link has expired or there is no bearer token set, redirect to /link-expired page
    useEffect(() => {
        if (!autoRefillBearerToken) {
            navigate('/link-expired?flow=auto-refill');
        }
    }, [autoRefillBearerToken]);

    // If there is no eligible rxs redirect to /no-prescriptions-eligible
    useEffect(() => {
        if ((!isLoading && !isFamilyHasRxs) || (!isLoading && !autoRefillIsCaregiver && !autoRefillPlanEligible)) {
            navigate('/no-prescriptions-eligible?flow=auto-refill');
        }
    }, [isLoading, isFamilyHasRxs, autoRefillPlanEligible, autoRefillIsCaregiver]);

    useEffect(() => {
        if (autoRefillFamilyData.length > 0 && autoRefillFamilyData[0].epostPatientNum) {
            setActiveDependentTab(autoRefillFamilyData[0].epostPatientNum);
        }
    }, [autoRefillFamilyData]);

    // Listen loaded values to set loading value as false, allowing the user to interact properly with the UI
    useEffect(() => {
        if (autoRefillRxsLoaded) {
            setIsLoading(false);
        }
    }, [autoRefillRxsLoaded, isFamilyHasRxs]);

    // Get Patient Data Family
    useEffect(() => {
        // Load data if the token hasn't expired and data hasn't been loaded
        if (autoRefillBearerToken && !autoRefillRxsLoaded) {
            // Get the auto refill information from API
            dispatch(
                autoRefillGetPatientDataRoutine.trigger({
                    onFailure: (error: string) => {
                        if (error !== undefined) {
                            handleShowErrorModal(error);
                        }
                    }
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoRefillRxsLoaded, autoRefillIsCaregiver, autoRefillBearerToken, autoRefillePostPatientNumber]);

    // Load Patient Adresses & Payment Cards
    useEffect(() => {
        // Validate if token is still valid
        if (autoRefillBearerToken) {
            dispatch(
                autoRefillGetPatientAdressesRoutine.trigger({
                    onFailure: (error: string) => {
                        if (error !== undefined) {
                            handleShowErrorModal(error);
                        }
                    }
                })
            );
            dispatch(
                autoRefillGetPatientPaymentCardsRoutine.trigger({
                    onFailure: (error: string) => {
                        if (error !== undefined) {
                            handleShowErrorModal(error);
                        }
                    }
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoRefillBearerToken]);

    // Load Rx prices
    useEffect(() => {
        if (
            // If filtered rxs are loaded or orderedRxs are loaded
            (orderedRxs.length > 0 || orderedRxs.length > 0) &&
            // and if is caregiver and we have loaded the dependents
            // or if is not caregiver but we have already a plan alias
            ((autoRefillIsCaregiver && dependents.length > 0) || (!autoRefillIsCaregiver && planAlias))
        ) {
            // Find the Prescriptions that should show price based on
            // corresponding object being rendered for each case (filtered or ordered)
            // TODO: When refactoring, we need to standardize and get values from a same
            // variable to simplify logic and enhance performance.
            const priceEligiblePrescriptions = orderedRxs.filter((prescription) => prescription.showPrice);

            // If we have prescriptions and we have loaded the addresses
            if (priceEligiblePrescriptions.length > 0 && autoRefillAddresses.length > 0) {
                // If there are Rxs to get price for, we trigger corresponding APIs
                dispatch(
                    drugDiscountPriceRoutine.trigger({
                        prescriptions: priceEligiblePrescriptions.map((prescription) => prescription.fullRxItem),
                        forceBirdiInsurance: !!(autoRefillAccountHasInsurance && ALLOW_INSURED_BIRDI_PRICE),
                        location: 'MedCabinet',
                        unAuthArea: 'auto-refill'
                    })
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderedRxs, filteredRxs, autoRefillAddresses]);

    return (
        <MarketingPageLayout
            headerImage={blueSkyBackground}
            headerImageClassName="profile-background"
            metaData={{ nodeTitle: 'Auto Refill' }}
            suppressApplicationPage={true}
            pageContentClassName="auto-refill-prescription-page"
        >
            <PageSection>
                <Container fluid className="auto-refill-prescriptions">
                    <InternalHeader
                        sectionIndex={0}
                        title={t('pages.autoRefill.prescriptions.headlineText')}
                        eyebrowText={t('pages.autoRefill.prescriptions.eyebrowText')}
                        body={
                            isLoading ? (
                                <Spinner isVisible={isLoading} t={t} />
                            ) : (
                                <>
                                    <p>{t('pages.autoRefill.prescriptions.greeting', { firstName })} </p>
                                    {isPatientAutoRefillEligible && (
                                        <button
                                            onClick={handleAutoRefillLearnMoreModal}
                                            className="auto-refill-greeting-learn-more-link"
                                            role="link"
                                        >
                                            {t('pages.autoRefill.prescriptions.learnMoreLink')}
                                        </button>
                                    )}
                                </>
                            )
                        }
                    />
                    <Row className={`${isLoading ? 'd-none' : 'd-flex'}`}>
                        <>
                            <Col xs={12} md={12} lg={8}>
                                <Row>
                                    <Col xs={12} md={12} lg={12}>
                                        {autoRefillFamilyData.length > 1 && (
                                            <PrescriptionTabs
                                                activeTab={'all'}
                                                activeDependentTab={activeDependentTab || autoRefillePostPatientNumber}
                                                isPrescriptionsAvailable={false}
                                                onTabItemChange={handleTabItemClick}
                                                dependents={dependents}
                                                myEpostPatientNum={autoRefillePostPatientNumber}
                                                hasFilterTabs={false}
                                                hasAutoRefillFlag={false}
                                            />
                                        )}
                                    </Col>
                                    {!autoRefillLoadingRxs && autoRefillBearerToken && orderedRxs.length > 0 ? (
                                        orderedRxs.map((prescription, index) => (
                                            <Col
                                                className="mb-4 flex-fill auto-refill-rx-card"
                                                key={`rx-${index}`}
                                                xs={6}
                                                sm={6}
                                                md={12}
                                            >
                                                <PrescriptionCard
                                                    key={`auto-refill-rx-card-${prescription.rxNumber}-${index}`}
                                                    isAddingToCart={false}
                                                    isCollapsed={true}
                                                    {...prescription}
                                                    autoRefillToggle={onChangeAutoRefill}
                                                    planAlias={planAlias}
                                                    isCaliforniaUser={isCaliforniaUser}
                                                    accountHasInsurance={autoRefillAccountHasInsurance}
                                                    zipcode={defaultAddress?.zipcode ?? ''}
                                                    autoRefillToggleBusy={autoRefillBusy}
                                                    planAllowsAutoRefill={isPatientAutoRefillEligible}
                                                    flow={RX_AVAILABLE_FLOWS.AUTO_REFILL}
                                                />
                                            </Col>
                                        ))
                                    ) : (
                                        <Col xs={12} md={12} lg={12}>
                                            <div className="easy-refill-prescriptions-empty">
                                                <EmptyState
                                                    icon={NoRefillsIcon}
                                                    title={t('components.emptyState.noRefills.autoRefill.title')}
                                                    description={t(
                                                        'components.emptyState.noRefills.autoRefill.description'
                                                    )}
                                                />
                                            </div>
                                        </Col>
                                    )}
                                </Row>
                            </Col>
                        </>

                        <Col xs={12} md={12} lg={4}>
                            {autoRefillRxsLoaded && (
                                <>
                                    <AutoRefillOtherInfo />
                                    {isPatientAutoRefillEligible && (
                                        <div className="auto-refill-prescriptions-other-info-message">
                                            <div className="icon">
                                                <CircleInfo variant="secondary" />
                                            </div>
                                            <div>
                                                <p
                                                    dangerouslySetInnerHTML={{
                                                        __html: sanitizeHtml(
                                                            t('pages.autoRefill.otherInformation.message'),
                                                            {
                                                                allowedTags: ['strong']
                                                            }
                                                        )
                                                    }}
                                                />
                                                <button
                                                    onClick={handleAutoRefillLearnMoreModal}
                                                    className="auto-refill-link"
                                                    role="link"
                                                >
                                                    {t('pages.autoRefill.prescriptions.learnMoreLink')}
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                        </Col>
                    </Row>
                    <></>
                </Container>
            </PageSection>
        </MarketingPageLayout>
    );
};

export default withUnauthenticatedSessionExpiration(AutoRefillPrescriptions, 'auto-refill');

export const query = graphql`
    query GetAutoRefillPrescriptionLanguageAndImageData($language: String) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        blueSkyBackground: file(relativePath: { eq: "assets/images/white-feathers-background-02.png" }) {
            id
            childImageSharp {
                fluid {
                    # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                    ...GatsbyImageSharpFluid
                }
                gatsbyImageData(formats: [AUTO])
            }
        }
    }
`;
